.hero-banner {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  background: var(--yellow-mandarine-gradient);

  color: var(--black);

  /* responsive */

  margin: 0 auto;
  padding: 2rem 3rem;
}

.hero-banner--yellow-mandarine {
  background: var(--yellow-mandarine-gradient);
}
.hero-banner--pink-yellow {
  background: var(--pink-yellow-gradient);
}

.hero-banner--blue-aqua {
  background: var(--blue-aqua-gradient);
}

.hero-banner--aqua-emerald {
  background: var(--aqua-emerald-gradient);
}

.hero-banner--emerald-yellow {
  background: var(--emerald-yellow-gradient);
}

.hero-banner__logo {
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: var(--white);
  border-radius: 50%;

  box-shadow: 0 2px 4px rgb(0 0 0 / 12%);

  /* responsive */

  width: 8rem;
  height: 8rem;
}

.hero-banner__image {
  width: 6.75rem;
  height: 6.75rem;
}

.hero-banner__image--small {
  width: 4.75rem;
  height: 4.75rem;
}

.hero-banner__headline {
  letter-spacing: -1.5px;

  /* responsive */

  margin: 1.5rem 0 8px 0;

  font-size: 3rem;
}

.hero-banner__description {
  max-width: 36.25rem;

  text-align: center;

  /* responsive */

  margin-bottom: 2rem;

  font-size: 20px;
  line-height: 2rem;
}

@media only screen and (max-width: 540px) {
  .hero-banner {
    padding: 2rem 1rem;
  }

  .hero-banner__logo {
    width: 6rem;
    height: 6rem;
  }

  .hero-banner__image {
    width: 4.5rem;
    height: 4.5rem;
  }

  .hero-banner__image--small {
    width: 3.75rem;
    height: 3.75rem;
  }

  .hero-banner__headline {
    font-size: 2rem;
  }

  .hero-banner__description {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
